import { Box, Container } from '@mui/material'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

export default function Footer() {
	return (
		<footer>
			<Container
				maxWidth='xl'
				sx={{  marginBottom: '30px' }}
			>		
					
						<Row>		
					<Col xs={12} lg={6} md={6} xl={6}>
					
						<Box className='footerTopBox'>
						<p style={{ fontWeight: '400', fontSize: '20px' }}>+7 (901) 459-7662</p>
						<p style={{ fontWeight: '400' , fontSize: '20px'}}>ava-drive@mail.ru</p>
							<p>Адрес: г. Барнаул Павловский тракт 313/2</p>
							<p>График работы: с 9:00 - 19:00, без обеда и выходных</p>
							<p>ООО «АВА Драйв» ИНН 4214044034 ОГРН 1234200009581</p>		
							<h1>Ava Drive 2024</h1>
						</Box>
					
					</Col>
					<Col xs={12} lg={6} md={6} xl={6}>
						<Box
							className='footerTopBox'
							sx={{ display: 'flex', justifyContent: 'end' }}
						>
							<Box className='footerBottomLinks'>
								<Link to='/reviews'>Отзывы</Link>
								<Link to='/partners'>Партнеры</Link>
								<Link to='/opening'>Вакансии</Link>
								<Link to='/about'>Гарантии</Link>
								<Link to='/privacyPolicy'>Политика конфиденциальности</Link>
								<Link to='/termOfUse'>Пользовательское соглашение</Link>
							</Box>
						</Box>
						<Box className='footerBottomLinks'>
							<a href='/search/quadbike'>Купить квадроцикл</a>
							<a href='/search/withmileage'> Купить автомобиль с пробегом</a>
							<a href='/search/nomileage'> Купить автомобиль без пробега</a>
						</Box>
					</Col>
				</Row>
			</Container>
		</footer>
	)
}
