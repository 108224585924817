import { Box } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import axios from '../../axios'
import { notify } from '../../pages/home-page/Home'
import { selectIsAuth } from '../../store/slices/auth'
export default function ModalPhone() {
  const [open, setOpen] = React.useState(false);
	const navigate = useNavigate()
	const [formValid, setFormValid] = React.useState(false)

	const isAuth = useSelector(selectIsAuth)

	const [inputValue, setInputValue] = React.useState({
		name: '',
		phone: '',
		car: '',
	})

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

	const handleSubmit = async event => {
		event.preventDefault()
		const trgt = event.target

		if (!isAuth) {
			try {
				const result = await axios.post('/order', {
					name: trgt.name.value,
					phone: trgt.phone.value,
					car: trgt.car.value,
				})

				navigate('/')

				notify()
		
			} catch (e) {
				console.error(e)
			}
		}
	}

	const changeHandler = ({ currentTarget }) => {
		const { value, name } = currentTarget
		setInputValue(prev => {
			const formValues = { ...prev, [name]: value }
			validate(formValues)
			return formValues
		})
		const validate = ({ name, phone }) => {
			const nameIsValid = name
			const phoneIsValid = phone
			const isCredentialsValid = nameIsValid && phoneIsValid
			if (isCredentialsValid !== formValid) setFormValid(isCredentialsValid)
		}
	}


  return (
    <React.Fragment>
      <button className='btn2 dopp orderNumber' onClick={handleClickOpen} style={{fontSize:'16px'}}>
		 		Заказать звонок
      </button>
      <Dialog
		
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Оставьте заявку и мы перезвоним Вам"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
					<form onSubmit={handleSubmit}>
					<Box className=' mt-3'>
					<label className='labelForm' htmlFor="name">Введите имя</label>
						<input
							className='inputLogin mb-3'
							value={inputValue.name}
							placeholder='Введите имя'
							type='text'
							name='name'
							onChange={changeHandler}
						/>
					<label className='labelForm' htmlFor="phone">Введите номер телефона</label>

						<input
							className='inputLogin mb-3'
							value={inputValue.phone}
							placeholder='Введите номер телефона'
							type='text'
							name='phone'
							onChange={changeHandler}
						/>
					<label className='labelForm' htmlFor="question">Введите вопрос</label>

						<input
							className='inputLogin mb-3'
							value={inputValue.car}
							placeholder='Введите вопрос'
							type='text'
							name='question'
							onChange={changeHandler}
						/>

						<Box>
							<button
								style={{ width: '100%' }}
								disabled={!formValid}
								type='submit'
								className='btn2 dopp mt-3'
								onClick={handleClose}
								
							>
								Отправить
							</button>
						</Box>
					</Box>
				</form>

          </DialogContentText>
        </DialogContent>
       
      </Dialog>
    </React.Fragment>
  );
}
