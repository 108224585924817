import { Box, Container } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'

const Partners = () => {
	return (
		<Container maxWidth='xl' fluid='md' >
			<Helmet>
				<title>Партнеры Ava Drive</title>
				<meta name='description' content='Партнеры Ava Drive' />
			</Helmet>
			<h1 style={{  fontWeight: '400' }}>Партнеры Ava Drive</h1>
			<Box>
				<img width={'100%'}
					src='https://sibkap.ru/wp-content/uploads/2021/08/logo.png'
					alt='sib'
				/>
				<p style={{fontSize: '1.7rem'}}>Позаботьтесь о будущем — вкладывая в настоящее!</p>
				<a href='https://sibkap.ru/'>Перейти на сайт</a>
				<p style={{ marginTop: '20px' }}>
					Кредитный потребительский кооператив «Сибирский Капитал» создан
					физическими лицами (пайщиками), добровольно объединившимися для
					оказания финансовой взаимопомощи. Основными целями деятельности
					Кооператива являются: аккумулирование финансовых средств и
					материальных ресурсов членов Кооператива; оказание консультационных
					услуг членам Кооператива, в том числе защита экономических интересов
					пайщиков; выдача займов членам кооператива; создание дополнительных
					источников поддержки пайщиков КПК. В России кредитные союзы создаются
					в виде кредитных потребительских кооперативов. В соответствии с
					Федеральным законом № 190-ФЗ от 18 июля 2009 года «О кредитной
					кооперации» кредитным потребительским кооперативом называется
					добровольное объединение физических и (или) юридических лиц на основе
					членства и по территориальному, профессиональному и (или) иному
					принципу в целях удовлетворения финансовых потребностей членов
					кредитного кооператива (пайщиков).
				</p>
			</Box>
		</Container>
	)
}

export default Partners
