import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { Container } from '@mui/system'
import 'easymde/dist/easymde.min.css'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import toast, { Toaster } from 'react-hot-toast'
import { IoClose } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import SimpleMDE from 'react-simplemde-editor'
import axios, { linkDev } from '../../axios'
import { selectIsAuth } from '../../store/slices/auth'
import styles from './AddPost.module.scss'

export const AddPost = () => {
	const { id } = useParams()
	const navigate = useNavigate()
	const isAuth = useSelector(selectIsAuth)
	const [isLoading, setLoading] = React.useState(false)
	const [imageUrl, setImageUrl] = React.useState('')
	const [imageUrl2, setImageUrl2] = React.useState('')
	const [imageUrl3, setImageUrl3] = React.useState('')
	const [imageUrl4, setImageUrl4] = React.useState('')
	const [imageUrl5, setImageUrl5] = React.useState('')
	const [engineBike, setEngineBike] = React.useState('')
	const [volumePower, setVolumePower] = React.useState('')
	const [maximumLoad, setMaximumLoad] = React.useState('')
	const [maximumSpeed, setMaximumSpeed] = React.useState('')

	const [text, setText] = React.useState('')
	const [price, setPrice] = React.useState('')
	const [title, setTitle] = React.useState('')
	const [desc, setDesc] = React.useState('')
	const [engine, setEngine] = React.useState('')
	const [power, setPower] = React.useState('')
	const [transmission, setTransmission] = React.useState('')
	const [drive, setDrive] = React.useState('')
	const [mileage, setMileage] = React.useState('')
	const [steeringWheel, setSteeringWheel] = React.useState('')
	const [color, setColor] = React.useState('')
	const [vin, setVin] = React.useState('')

	const [tags, setTags] = React.useState('')
	const [category, setCategory] = React.useState('')
	const inputFileRef = React.useRef(null)
	const inputFileRef2 = React.useRef(null)
	const inputFileRef3 = React.useRef(null)
	const inputFileRef4 = React.useRef(null)
	const inputFileRef5 = React.useRef(null)

	const isEditing = Boolean(id)
	const admin = useSelector(state => state.auth.data)
	const notify = () => toast('Машина добавлена')
	const handleChangeFile = async event => {
		try {
			const formData = new FormData()
			const file = event.target.files[0]

			formData.append('image', file)
			const { data } = await axios.post('/upload', formData)
			console.log(data)
			setImageUrl(data.url)
		} catch (err) {
			console.log(err)
			alert('ошибка при загрузке фото')
		}
	}
	const handleChangeFile2 = async event => {
		try {
			const formData = new FormData()
			const file = event.target.files[0]
			formData.append('image2', file)
			const { data } = await axios.post('/upload2', formData)
			setImageUrl2(data.url)
		} catch (err) {
			console.log(err)
			alert('ошибка при загрузке фото')
		}
	}
	const handleChangeFile3 = async event => {
		try {
			const formData = new FormData()
			const file = event.target.files[0]
			formData.append('image3', file)
			const { data } = await axios.post('/upload3', formData)
			setImageUrl3(data.url)
		} catch (err) {
			console.log(err)
			alert('ошибка при загрузке фото')
		}
	}
	const handleChangeFile4 = async event => {
		try {
			const formData = new FormData()
			const file = event.target.files[0]
			formData.append('image4', file)
			const { data } = await axios.post('/upload4', formData)
			setImageUrl4(data.url)
		} catch (err) {
			console.log(err)
			alert('ошибка при загрузке фото')
		}
	}
	const handleChangeFile5 = async event => {
		try {
			const formData = new FormData()
			const file = event.target.files[0]
			formData.append('image5', file)
			const { data } = await axios.post('/upload5', formData)
			setImageUrl5(data.url)
		} catch (err) {
			console.log(err)
			alert('ошибка при загрузке фото')
		}
	}

	const onClickRemoveImage = () => {
		setImageUrl('')
	}
	const onClickRemoveImage2 = () => {
		setImageUrl2('')
	}
	const onClickRemoveImage3 = () => {
		setImageUrl3('')
	}
	const onClickRemoveImage4 = () => {
		setImageUrl4('')
	}
	const onClickRemoveImage5 = () => {
		setImageUrl5('')
	}

	const onChange = React.useCallback(value => {
		setText(value)
	}, [])

	const onSubmit = async () => {
		try {
			setLoading(true)
			const fields = {
				title,
				imageUrl,
				imageUrl2,
				imageUrl3,
				imageUrl4,
				imageUrl5,
				tags,
				text,
				price,
				category,
				engine,
				power,
				transmission,
				drive,
				mileage,
				steeringWheel,
				color,
				vin,
				engineBike,
				volumePower,
				maximumLoad,
				maximumSpeed

			}
			const { data } = isEditing
				? await axios.patch(`/posts/${id}`, fields)
				: await axios.post('/posts', fields)

			const _id = isEditing ? id : data._id
			notify()
			navigate('/')
		} catch (err) {
			console.warn(err)
			alert(
				'Ошибка. Нужно проверить правильность заполнения! Нажми ок. Проверь еще и попробуй снова'
			)
		}
	}

	useEffect(() => {
		if (admin && admin.admin !== '1') {
			navigate('/')
		}
		if (admin == null) {
			navigate('/')
		}
		if (id) {
			axios
				.get(`/posts/${id}`)
				.then(({ data }) => {
					setTitle(data.title)
					setText(data.text)
					setTags(data.tags.join(','))
					setImageUrl(data.imageUrl)
					setImageUrl2(data.imageUrl2)
					setImageUrl3(data.imageUrl3)
					setImageUrl4(data.imageUrl4)
					setImageUrl5(data.imageUrl5)
					setPrice(data.price)
					setCategory(data.category)
					setEngine(data.engine)
					setPower(data.power)
					setTransmission(data.transmission)
					setDrive(data.drive)
					setMileage(data.mileage)
					setSteeringWheel(data.steeringWheel)
					setColor(data.color)
					setVin(data.vin)
					setEngineBike(data.engineBike)
					setVolumePower(data.volumePower)
					setMaximumLoad(data.maximumLoad)
					setMaximumSpeed(data.maximumSpeed)
				})
				.catch(err => {
					console.warn(err)
					alert('Ошибка при получении поста')
				})
		}
	}, [id])

	const options = React.useMemo(
		() => ({
			spellChecker: false,
			maxHeight: '400px',
			autofocus: true,
			placeholder: 'Опишите транспорт, более подробно',
			status: false,
			autosave: {
				enabled: true,
				delay: 1000,
			},
		}),
		[]
	)

	return (
		<Container maxWidth='xl'  className='mt-5'>
			<Helmet>
				<title>Добавить машину</title>
				<meta
					name='description'
					content='Это страница, чтобы добавить машину'
				/>
			</Helmet>
			<div>
				<Toaster />
			</div>
			<h2 className='mb-4'>Загрузка фото</h2>
			<Paper style={{ background: 'inherit' }}>
				<Box className='photoLoadingBox' sx={{ display: 'flex' }}>
					<button
						className='btn2 dopp me-2'
						onClick={() => inputFileRef.current.click()}
					>
						Главная
					</button>
					<button
						className='btn2 dopp me-2'
						onClick={() => inputFileRef2.current.click()}
					>
						Сзади
					</button>
					<button
						className='btn2 dopp me-2'
						onClick={() => inputFileRef3.current.click()}
					>
						Салон
					</button>
					<button
						className='btn2 dopp me-2'
						onClick={() => inputFileRef4.current.click()}
					>
						Двигатель
					</button>
					<button
						className='btn2 dopp me-2'
						onClick={() => inputFileRef5.current.click()}
					>
						Багажник
					</button>
				</Box>
			
				<input
					ref={inputFileRef}
					type='file'
					onChange={handleChangeFile}
					hidden
					name='image'
				/>

				<input
					ref={inputFileRef2}
					type='file'
					onChange={handleChangeFile2}
					hidden
					name='image2'
				/>

				<input
					ref={inputFileRef3}
					type='file'
					onChange={handleChangeFile3}
					hidden
					name='image3'
				/>

				<input
					ref={inputFileRef4}
					type='file'
					onChange={handleChangeFile4}
					hidden
					name='image4'
				/>

				<input
					ref={inputFileRef5}
					type='file'
					onChange={handleChangeFile5}
					hidden
					name='image5'
				/>

				<div
					style={{ display: 'flex', marginTop: '40px' }}
					className='BoxImageDownl'
				>
					{imageUrl && (
						<Box sx={{ position: 'relative', mr: 1 }}>
							<button onClick={onClickRemoveImage} className='delPhoto'>
								<IoClose size={25} />
							</button>
							<img
								className={styles.image}
								src={`${linkDev}${imageUrl}`}
								alt='upload car'
							/>
						</Box>
					)}
					{imageUrl2 && (
						<Box sx={{ position: 'relative', mr: 1 }}>
							<button onClick={onClickRemoveImage2} className='delPhoto'>
								<IoClose size={25} />
							</button>
							<img
								className={styles.image}
								src={`${linkDev}${imageUrl2}`}
								alt='Uploaded car'
							/>
						</Box>
					)}
					{imageUrl3 && (
						<Box sx={{ position: 'relative', mr: 1 }}>
							<button onClick={onClickRemoveImage3} className='delPhoto'>
								<IoClose size={25} />
							</button>
							<img
								className={styles.image}
								src={`${linkDev}${imageUrl3}`}
								alt='Uploaded car'
							/>
						</Box>
					)}
					{imageUrl4 && (
						<Box sx={{ position: 'relative', mr: 1 }}>
							<button onClick={onClickRemoveImage4} className='delPhoto'>
								<IoClose size={25} />
							</button>
							<img
								className={styles.image}
								src={`${linkDev}${imageUrl4}`}
								alt='Uploaded car'
							/>
						</Box>
					)}
					{imageUrl5 && (
						<Box sx={{ position: 'relative' }}>
							<button onClick={onClickRemoveImage5} className='delPhoto'>
								<IoClose size={25} />
							</button>
							<img
								className={styles.image}
								src={`${linkDev}${imageUrl5}`}
								alt='Uploaded car'
							/>
						</Box>
					)}
				</div>
				<p className='mt-3' style={{ color: "white", fontSize: '1.7rem' }}>Основные параметры</p>
				<label htmlFor="" className='labelForm'>Название автомобиля, включая его год</label>
				<input
					className='inputLogin mb-1'
					placeholder='Вводить без запятых. Пример Honda Civic 2020'
					fullWidth
					type='text'
					value={title}
					onChange={e => setTitle(e.target.value)}
				/>
				<label htmlFor="" className='labelForm'>Цена</label>
				<input
					className='inputLogin mb-1'
					placeholder='Вводить без пробелов'
					fullWidth
					type='text'
					value={price}
					onChange={e => setPrice(e.target.value)}
				/>
				<label htmlFor="" className='labelForm'>Категория</label>
				<select
					className='inputLogin mb-1'
					sx={{ color: '#404040' }}
					value={category}
					label='Категория'
					placeholder='Категория'
					onChange={e => setCategory(e.target.value)}
					fullWidth
				>
					<option value={'0'}>Не выбрано</option>
					<option value={'1'}>С ПРОБЕГОМ</option>
					<option value={'2'}>БЕЗ ПРОБЕГА</option>
					<option value={'3'}>НОВИНКИ</option>
					<option value={'4'}>СПЕЦПРЕДЛОЖЕНИЯ</option>
					<option value={'5'}>КВАДРОЦИКЛЫ</option>
					<option value={'6'}>СКУТЕРЫ</option>
				</select>
				<label htmlFor="" className='labelForm'>Теги или краткое описание</label>
				<input
						className='inputLogin mb-1'
						placeholder='Ввовдить через запяту. Например: Хорошее техническое состояние, Седан'
						fullWidth
						type='text'
						value={tags}
						onChange={e => setTags(e.target.value)}
					/>

				<p className='mt-3' style={{ color: "white", fontSize: '1.7rem' }}>Доп параметры</p>
				<Box className='boxAddPost'>
					
					<input
						className='inputLogin mb-1'
						placeholder='Двигатель, вводить только цифры'
						fullWidth
						type='text'
						value={engine}
						onChange={e => setEngine(e.target.value)}
					/>
					<input
						className='inputLogin mb-1'
						placeholder='Мощность, вводить только цифры'
						fullWidth
						type='text'
						value={power}
						onChange={e => setPower(e.target.value)}
					/>
					<input
						className='inputLogin mb-1'
						placeholder='Трансмиссия'
						fullWidth
						type='text'
						value={transmission}
						onChange={e => setTransmission(e.target.value)}
					/>
					<input
						className='inputLogin mb-1'
						placeholder='Привод'
						fullWidth
						type='text'
						value={drive}
						onChange={e => setDrive(e.target.value)}
					/>
				</Box>
				<Box className='boxAddPost'>
					<input
						className='inputLogin mb-1'
						placeholder='Пробег, вводить только цифры'
						fullWidth
						type='text'
						value={mileage}
						onChange={e => setMileage(e.target.value)}
					/>
					<input
						className='inputLogin mb-1'
						placeholder='Руль'
						fullWidth
						type='text'
						value={steeringWheel}
						onChange={e => setSteeringWheel(e.target.value)}
					/>
					<input
						className='inputLogin mb-1'
						placeholder='Цвет'
						fullWidth
						type='text'
						value={color}
						onChange={e => setColor(e.target.value)}
					/>
					<input
						className='inputLogin mb-1'
						placeholder='VIN'
						fullWidth
						type='text'
						value={vin}
						onChange={e => setVin(e.target.value)}
					/>
				</Box>
				<Box>
				<p className='mt-3' style={{ color: "white", fontSize: '1.7rem' }}>Доп параметры для квадроциклов и скутеров</p>
					<input
						className='inputLogin mb-1'
						placeholder='Двигатель. Например: Бензиновый, Электрический'
						fullWidth
						type='text'
						value={engineBike}
						onChange={e => setEngineBike(e.target.value)}
					/>
					<input
						className='inputLogin mb-1'
						placeholder='Объем / мощность для квадроцикла или Запас хода для скутера, вводить только цифры'
						fullWidth
						type='text'
						value={volumePower}
						onChange={e => setVolumePower(e.target.value)}
					/>
						<input
						className='inputLogin mb-1'
						placeholder='Максимальная нагрузка. Вводить только цифры'
						fullWidth
						type='text'
						value={maximumLoad}
						onChange={e => setMaximumLoad(e.target.value)}
					/>
						<input
						className='inputLogin mb-1'
						placeholder='Максимальная скорость. Вводить только цифры'
						fullWidth
						type='text'
						value={maximumSpeed}
						onChange={e => setMaximumSpeed(e.target.value)}
					/>

				</Box>
				<p className='mt-3' style={{ color: "white", fontSize: '1.7rem' }}>Описание</p>
				<SimpleMDE
					className={styles.editor}
					value={text}
					onChange={onChange}
					options={options}
				/>
				<div
					className='mt-2 mb-5'
					style={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<button onClick={onSubmit} className='btn2 dopp'>
						{isEditing ? 'Сохранить' : 'Опубликовать'}
					</button>
					<a href='/' className='btn2 dopp'>
						Отмена
					</a>
				</div>
			</Paper>
		</Container>
	)
}
