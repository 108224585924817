import DeleteIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import { Box } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import clsx from 'clsx'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { fetchRemovePost } from '../../store/slices/posts'
import styles from './Card.module.scss'
import { PostSkeleton } from './Skeleton'

const categoryMapping = {
	all: '0',
	withmileage: '1',
	nomileage: '2',
	quadbike: '5',
	scooter: '6',
	new: '3',
	specialoffers: '4',
}

export const Card = ({
	onTagClick,
	tags,
	_id,
	title,
	mileage,
	imageUrl,
	transmission,
	children,
	isFullPost,
	isLoading,
	isEditable,
	price,
	power,
	engine,
	category,
	engineBike,
	volumePower,
	maximumLoad,
	maximumSpeed

}) => {
	const dispatch = useDispatch()

	if (isLoading) {
		return <PostSkeleton />
	}
	const numbFmt = new Intl.NumberFormat('ru-RU').format(price)
	const onClickRemove = () => {
		if (window.confirm('Вы действительно хотите удалить пост?')) {
			dispatch(fetchRemovePost(_id))
		}
	}
	const categoryName = Object.keys(categoryMapping).find(
		key => categoryMapping[key] === category
	)
	const sanitizedTitle = title
		.toLowerCase()
		.replace(/[^\w ]+/g, '')
		.replace(/ +/g, '-')

	return (
		<Box className={clsx(styles.root, { [styles.rootFull]: isFullPost })}>
			{isEditable && (
				<Box className={styles.editButtons}>
					<Link to={`/posts/${_id}/edit`}>
						<IconButton style={{ color: 'white' }}>
							<EditIcon />
						</IconButton>
					</Link>
					<IconButton onClick={onClickRemove} style={{ color: 'white' }}>
						<DeleteIcon />
					</IconButton>
				</Box>
			)}
			<Link to={`/${categoryName}/${sanitizedTitle}/${_id}`}>
				{imageUrl && (
					<img
						className={clsx(styles.image, { [styles.imageFull]: isFullPost })}
						src={imageUrl}
						alt={title}
					/>
				)}
				</Link>

				<Box className={styles.wrapper}>
					<Box className={styles.indention}>
					<Link to={`/${categoryName}/${sanitizedTitle}/${_id}`}>
						<p className='mainTitle'>{title}</p>
						<p style={{ fontWeight: '400', fontSize: '1.2rem', margin: '0px' }}>
							Цена: {numbFmt}₽
						</p>
						{category === '1' ? (
							<div className='CardStoreBox'>
							<p>Трансмиссия: {transmission}</p>
							<p>Пробег: {mileage} км</p>
							<p>Двигатель: {engine} л.</p>
							<p>Мощность: {power} л.с </p>
						</div>
						): ''}
						{category === '2' ? (
							<div className='CardStoreBox'>
							<p>Трансмиссия: {transmission}</p>
							<p>Пробег: {mileage} км</p>
							<p>Двигатель: {engine} л.</p>
							<p>Мощность: {power} л.с </p>
						</div>
						): ''}
						{category === '3' ? (
							<div className='CardStoreBox'>
							<p>Трансмиссия: {transmission}</p>
							<p>Пробег: {mileage} км</p>
							<p>Двигатель: {engine} л.</p>
							<p>Мощность: {power} л.с </p>
						</div>
						): ''}
						{category === '4' ? (
							<div className='CardStoreBox'>
							<p>Трансмиссия: {transmission}</p>
							<p>Пробег: {mileage} км</p>
							<p>Двигатель: {engine} л.</p>
							<p>Мощность: {power} л.с </p>
						</div>
						): ''}
						{category === '5' ? (
							<div className='CardStoreBox'>
							<p>Двигатель : {engineBike}</p>
							<p>Объем / мощность: {volumePower}</p>
							<p>Максимальная нагрузка: {maximumLoad} кг</p>
							<p>Максимальная скорость: {maximumSpeed} км/ч</p>
						</div>
						): ''}
						{category === '6' ? (
							<div className='CardStoreBox'>
							<p>Двигатель : {engineBike}</p>
							<p>Максимальная нагрузка: {maximumLoad} кг</p>
							<p>Максимальная скорость: {maximumSpeed} км/ч</p>
							<p>Запас хода: {volumePower} км</p>
						</div>
						): ''}
					
						</Link>
						
						<p>
							Описание: {tags.map((tag, index) => 
								<span 
									className='tagForm' 
									key={index} 
									onClick={() => onTagClick(tag)} 
									style={{ cursor: 'pointer', textDecoration: 'underline' }}>
									{tag}
								</span>
							)}
						</p>
						<Link to={`/${categoryName}/${sanitizedTitle}/${_id}`}>
						<button className='btn2 dopp'>Купить</button>
						</Link>
						{children && <div className={styles.content}>{children}</div>}
					</Box>
				</Box>
			

	
		</Box>
	)
}
