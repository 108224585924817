import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Box, Grid } from "@mui/material";

export default function SkeletonFullPost() {
  return (
    <Grid container spacing={2} mt={2}>
      <Grid item xs={12} md={8} sx={{ mt: 2 }}>
        <Stack spacing={1}>
          <Skeleton
            sx={{ height: { xs: "300px", md: "600px", background: "#0f0f10" } }}
            variant="rectangular"
            width="100%"
          />
          <Skeleton variant="text" sx={{ background: "#0f0f10" }} />
        </Stack>
      </Grid>
      <Grid item xs={12} md={4}>
        <Stack spacing={1}>
          <Skeleton variant="text" sx={{ background: "#0f0f10" }} />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              background: "#0f0f10",
            }}
          >
            <Skeleton
              variant="rectangular"
              width={210}
              height={60}
              sx={{ background: "#0f0f10" }}
            />
            <Skeleton
              variant="rounded"
              width={210}
              height={60}
              sx={{ background: "#0f0f10" }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={600}
              sx={{ background: "#0f0f10" }}
            />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}
