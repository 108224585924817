import { configureStore, combineReducers } from "@reduxjs/toolkit";
import cartReducer from "./slices/cart";
import { postsReducer } from "./slices/posts";
import { authReducer } from "./slices/auth";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  cart: cartReducer,
  posts: postsReducer,
  auth: authReducer,
});
const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export default store;

// reducer: {
//   games: gamesReducer,
//   cart: cartReducer,
//   posts: postsReducer,
//   auth: authReducer,
// },
