import { Box } from "@mui/material"
import React from "react"
import { Col, Row } from "react-bootstrap"
import car1 from "./car1.jpg"
import car2 from "./car2.jpg"

const BuyBlock = () => {
  return (
    <Box className="mt-5 mb-5">
      <Row className="mt-5 mb-5">
        <Col
          xs={12}
          lg={6}
          md={6}
          xl={6}
          className="titleExampleCar"
        >
          <p style={{ fontWeight: "400", fontSize: '1.9rem' }}>
            Преимущества выкупа авто в AVA DRIVE
          </p>
          <p>
            Задумываясь о продаже своего автомобиля, каждый из нас всегда хочет
            получить как можно больше денег, не тратя на это свое время и силы.
            AVA DRIVE решит это вопрос лучше всех. Почему? Все просто.
          </p>
          <p>
            Мы предлагаем срочный выкуп автомобилей в Барнауле, при котором всю
            сумму Вы получаете сразу после заключения сделки по автовыкупу.
          </p>
          <p>
            Экспертиза и опыт сотрудников AVA DRIVE, гарантируют комфорт и
            безопасность продажи Вашего автомобиля.
          </p>
        </Col>
        <Col xs={12} lg={6} md={6} xl={6}>
          <img
            src={car1}
            alt="carExample"
            className="carExample "
          />
        </Col>
      </Row>
      <Row>
        <Col
          xs={12}
          lg={6}
          md={6}
          xl={6}
          className="scroll__image"
        >
          <img src={car2} alt="carExample" className="carExample" />
        </Col>
        <Col
          xs={12}
          lg={6}
          md={6}
          xl={6}
          className="titleExampleCar"
        >
          <p style={{ fontWeight: "400", fontSize: '1.9rem' }}>Какие авто мы покупаем?</p>
          <p>
            В автосалонe AVA DRIVE можно продать автомобиль любой марки, модели,
            модификации. Вся стоимость выплачивается сразу.
          </p>
          <p>Что влияет на стоимость автовыкупа?</p>

          <p>- рыночная стоимость автомобиля на текущий момент</p>
          <p>
            - год производства и километраж (больше всего популярны машины с
            периодом эксплуатации не более 3 лет)
          </p>
          <p>- техническое состояние и внешний вид автомобиля</p>
        </Col>
      </Row>
    </Box>
  );
};

export default BuyBlock;
