import { Box, Rating } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import * as React from 'react'
import axios from '../../axios'
export default function ModalReviews() {
  const [open, setOpen] = React.useState(false);

	const [formValid, setFormValid] = React.useState(false)
	const [value, setValue] = React.useState(5);

	

	const [name, setName] = React.useState('');
	const [text, setText] = React.useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

	
	const handleSubmitComment = async () => {
    const reviewData = {
       
				name: name,
        text: text
    };

    try {
        await axios.post('/comment', reviewData);
				
    } catch (error) {
        console.error(error);
        alert('Ошибка при отправке отзыва');
    }
};
	


  return (
    <React.Fragment>
      <button className='btn2 dopp' onClick={handleClickOpen}>
		 		Отставить отзыв
      </button>
      <Dialog
		
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Поделитесь мнением о Ava Drive"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
					<form onSubmit={handleSubmitComment}>
					<Box className=' mt-3'>
					<label className='labelForm' htmlFor="name">Поставить оценку</label>
					<div>
					<Rating
							name="simple-controlled"
							value={value}
							onChange={(event, newValue) => {
								setValue(newValue);
							}}
							/>
					</div>
							<label className='labelForm' htmlFor="name">Введите имя</label>
						<input
							className='inputLogin mb-1 mt-1'
							value={name}
							placeholder='Введите имя'
							type='text'
							name='name'
							onChange={e => setName(e.target.value)}
						/>
						
					<label className='labelForm mb-1 mt-1' htmlFor="name">Введите текст</label>
					<textarea  onChange={e => setText(e.target.value)} value={text} className='inputLogin mb-3' name="send" id="send" placeholder='Введите текст'/>
						<Box>
							<button
								style={{ width: '100%' }}
								
								type='submit'
								className='btn2 dopp mt-3'
								
							>
								Отправить
							</button>
						</Box>
					</Box>
				</form>

          </DialogContentText>
        </DialogContent>
       
      </Dialog>
    </React.Fragment>
  );
}
