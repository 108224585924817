import { Box, Rating, Typography } from '@mui/material'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const Reviews = () => {
	const [value, setValue] = React.useState(5)

	return (
		<Box sx={{ mb: 4, mt: 4 }}>
			<p className='titleMain'>Отзывы о нашем салоне </p>
			<p className='titleTwo'>НАМ ДОВЕРИЛИСЬ СОТНИ КЛИЕНТОВ</p>

			<Box>
				<Row>
					<Col xs={12} lg={4} md={4} xl={4}>
						<Box
							sx={{
								p: 1,
								borderRadius: '10px',
								mt: 2,
							}}
							
						>
							<p style={{ fontWeight: '600', fontSize: '1.7rem' }}>Антон</p>
							<p
								style={{
									color: '#7b7b7b',
									fontSize: '12px',
									marginBottom: '5px',
								}}
							>
								12 декабря 2023
							</p>
							<Typography component='legend'>Оценка пользователя:</Typography>
							<Rating name='read-only' value={value} readOnly />
							<p>
								Хороший автосалон. Машины в отличном состоянии, порадовал
								быстрый выход на сделку, договорились о скидке, ее сделали,
								допов всяких и тд не навязывали, все отлично, машина встала на
								учёт без проблем, рекомендую, спасибо Стоит отметить работу
								сотрудников, она просто на высшем уровне👍🏻👍🏻👍🏻
							</p>
						</Box>
					</Col>
					<Col xs={12} lg={4} md={4} xl={4}>
						<Box
							
							sx={{
								p: 1,
								borderRadius: '10px',
								mt: 2,
							}}
						>
							<p style={{ fontWeight: '600', fontSize: '1.7rem' }}>Владимир</p>
							<p
								style={{
									color: '#7b7b7b',
									fontSize: '12px',
									marginBottom: '5px',
								}}
							>
								15 июля 2023
							</p>
							<Typography component='legend'>Оценка пользователя:</Typography>
							<Rating name='read-only' value={value} readOnly />
							<p>
								Спасибо вам огромное, помогли мне подобрать лучшую машину по
								цене. сделали огромную скидку, был очень доволен, все сделали по
								красоте, сотрудники молодцы, все объяснили и показали, дефекты и
								тд, встала на учет как влитая, езжу и радуюсь теперь, короче
								огромное спасибо вашему автосалону еще раз говорю, люблю вас
							</p>
						</Box>
					</Col>
					<Col xs={12} lg={4} md={4} xl={4}>
						<Box
							
							sx={{
								p: 1,
								borderRadius: '10px',
								mt: 2,
							}}
						>
							<p style={{ fontWeight: '600', fontSize: '1.7rem' }}>Анатолий</p>
							<p
								style={{
									color: '#7b7b7b',
									fontSize: '12px',
									marginBottom: '5px',
								}}
							>
								1 марта 2024
							</p>
							<Typography component='legend'>Оценка пользователя:</Typography>
							<Rating name='read-only' value={value} readOnly />
							<p>
								После продажи машины и просмотра многих «утилей» у собственников
								да еще и по конским ценам, решил посмотреть машины в
								автосалонах. Машины в отличном состоянии, если есть нюансы
								предупреждают (окрасы и тд), уже о многом говорит! Не втюхивают
								то что мне не нужно, лишь бы продать, а действительно помогают с
								выбором!
							</p>
						</Box>
					</Col>
				</Row>
				<Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
					<Link to='/reviews' className='btn2 dopp reviewsLink'>
						Посмотреть все отзывы
					</Link>
				</Box>
			</Box>
		</Box>
	)
}

export default Reviews
