import React from 'react'

const TextDescCar = () => {
	return (
		<div>
			<p>
				Автомобиль присутствует практически в каждом доме. Особенно важен он для
				людей, проживающих в загородных районах, поскольку без него им трудно
				добраться до места работы. Однако городским жителям автомобиль также
				необходим во многих ситуациях. Без машины трудно представить себе отдых
				на природе, так как общественный транспорт не доставит в желаемые уголки
				природы.
			</p>

			<div>
				<p className='titleMain'>
					Особенности и преимущества покупки автомобилей у нас
				</p>
				<ul>
					<li>
						Все автомобили проходят проверку на юридическую чистоту, и мы
						гарантируем отсутствие каких-либо проблем с документами. Выберите
						автомобиль или квадроцикл, который подходит вам по всем параметрам,
						и свяжитесь с нами для обсуждения условий покупки.
					</li>
					<li>
						Основными принципами в работе компании являются: ориентация на
						качественное удовлетворение потребностей клиентов, профессионализм в
						работе и порядочность.
					</li>
				</ul>

				<p className='titleMain'>Почему стоит купить автомобиль у нас</p>
				<ul>
					<li>Подтвержденное качество</li>
					<li>Доступные цены</li>
					<li>Гарантия</li>
				</ul>
			</div>
			<div>
				<p className='titleMain'>Как сделать заказ</p>
				<ul>
					<li>Нажать на кнопку "Купить"</li>
					<li>Заполните необходимые поля и оформите заявку </li>
					<li>
						Наши менеджеры перезвонят для подтверждения заказа и
						проконсультируют по всем вопросам!
					</li>
				</ul>
			</div>
		</div>
	)
}

export default TextDescCar
