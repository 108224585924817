import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import axios from '../../axios'
import { notify } from '../../pages/home-page/Home'
import { selectIsAuth } from '../../store/slices/auth'
import {
	clearCart,
	deleteItemFromCart,
	setItemInCart,
} from '../../store/slices/cart'

export const CarPhoneFullPost = ({ game }) => {
	const [show, setShow] = useState(false)
	const navigate = useNavigate()
	const isAuth = useSelector(selectIsAuth)
	const [formValid, setFormValid] = React.useState(false)
	const [inputValue, setInputValue] = React.useState({
		name: '',
		phone: '',
	})
	const [isShowForm, setIsShowForm] = React.useState(false)

	const dispatch = useDispatch()
	const items = useSelector(state => state.cart.itemsInCart)
	const isItemInCart = items.some(item => item._id === game._id)

	const handleSubmit = async event => {
		event.preventDefault()
		const trgt = event.target

		if (!isAuth) {
			try {
				const result = await axios.post('/order', {
					name: trgt.name.value,
					phone: trgt.phone.value,
					items: items,
				})

				navigate('/')
				dispatch(clearCart())
				notify()
			} catch (e) {
				console.error(e)
			}
		}
	}
	const changeHandler = ({ currentTarget }) => {
		const { value, name } = currentTarget
		setInputValue(prev => {
			const formValues = { ...prev, [name]: value }
			validate(formValues)
			return formValues
		})
		const validate = ({ name, phone }) => {
			const nameIsValid = name
			const phoneIsValid = phone
			const isCredentialsValid = nameIsValid && phoneIsValid
			if (isCredentialsValid !== formValid) setFormValid(isCredentialsValid)
		}
	}

	const handleClick = e => {
		e.stopPropagation()
		if (isItemInCart) {
			dispatch(deleteItemFromCart(game._id))
		} else {
			dispatch(setItemInCart(game))
			setIsShowForm(current => !current)
		}
	}
	const toggleContent = () => {
    setShow(true);
  };
	

	return (
		<>
			<Box>
	
				<form onSubmit={handleSubmit}>
					<Box className='order-page-inputs-item mt-3'>
						<h2>
							Понравился {game.category === '5' && 'квадроцикл?' }
						{game.category === '6' && 'скутер?' }
						{game.category === '2' && 'автомобиль?' }
						{game.category === '3' && 'автомобиль?' }
						{game.category === '1' && 'автомобиль?' }
						{game.category === '4' && 'автомобиль?' }
						</h2>
			{show && (
				<>
							<p>Заполните данные и мы позвоним вам </p>
						<input
							className='inputLogin mb-3'
							value={inputValue.name}
							placeholder='Введите имя'
							type='text'
							name='name'
							onChange={changeHandler}
						/>
						<input
							className='inputLogin mb-3'
							value={inputValue.phone}
							placeholder='Введите номер телефона'
							type='text'
							name='phone'
							onChange={changeHandler}
						/>

						<Box>
							<button
								style={{ width: '100%' }}
								disabled={!formValid}
								type='submit'
								className='btn2 dopp'
								onClick={handleClick}
							>
								Отправить
							</button>
						</Box></>
			)}
					</Box>
				</form>
			{!show && 	<button
								style={{ width: '100%', marginTop: '20px'}}
								className='btn2 dopp'
								onClick={toggleContent}
							>
								Купить
							</button>}
							
			</Box>
		</>
	)
}
