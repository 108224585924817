import { Box } from "@mui/material"
import { Map, Placemark, YMaps } from "@pbe/react-yandex-maps"
import React from "react"

const MapBlog = () => {
  return (
    <Box style={{ marginBottom: "30px" }}>
      <Box sx={{ mb: "40px", mt: "40px" }}>
        <p className='titleMain'>Автосалон расположен по адресу </p>
        <p className='titleTwo'>
          Павловский тракт 313/2, работаем каждый день с 9:00 - 19:00
        </p>
        
      </Box>
      <div>
        <YMaps query={{ apikey: "64d635a0-4beb-4eb7-9cc4-b20453910bb3" }}>
          <Box mt={2}>
            <Map
              defaultState={{
                center: [53.34696262457308, 83.63594384079042],
                zoom: 16,
              }}
              width="100%"
              height="350px"
            >
              <Placemark
                defaultGeometry={[53.34696262457308, 83.63594384079042]}
              />
            </Map>
          </Box>
        </YMaps>
      </div>
    </Box>
  );
};

export default MapBlog;
