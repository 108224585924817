import { Box, Rating, Typography } from '@mui/material'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

const TextDescScooter = () => {
	return (
		<div>
			<p>
				Хотите выгодно купить Скутеры по доступным ценам в Барнауле? Здесь вы
				найдете все необходимые товары. Мы предлагаем широкий ассортимент
				продукции по самым выгодным ценам, чтобы удовлетворить все ваши
				потребности. Изучите раздел Скутеры, здесь вы сможете сравнить
				технические характеристики каждого товара, посмотреть отзывы покупателей
				и фотографии моделей – столь подробная информация поможет вам выбрать
				модель, идеально подходящую по всем параметрам.
			</p>

			<div>
				<p className='titleMain'>Особенности и преимущества скутеров</p>
				<ul>
					<li>
						Согласно закону, права на скутер можно получить с 16 лет. Это
						значит, даже несовершеннолетний подросток может испытать на себе
						бурю эмоций от передвижения на данном виде транспорта;
					</li>
					<li>Электрические скутеры не требуют регистрации;</li>
					<li>Как ниёчто другое подходит для передвижения по городу;</li>
					<li>
						Данный вид транспорта отличается легкостью в управлении и
						манёвренностью, что дает возможность быстро освоиться даже новичкам
					</li>
				</ul>

				<p className='titleMain'>Почему стоит купить скутер у нас</p>
				<ul>
					<li>Подтвержденное качество</li>
					<li>Доступные цены</li>
					<li>Гарантия</li>
				</ul>
			</div>
			<div>
				<p className='titleMain'>Как сделать заказ</p>
				<ul>
					<li>Нажать на кнопку "Купить"</li>
					<li>Заполните необходимые поля и оформите заявку </li>
					<li>
						Наши менеджеры перезвонят для подтверждения заказа и
						проконсультируют по всем вопросам!
					</li>
				</ul>
			</div>
			<div>
				<p className='titleMain'>Отзывы наших покупателей</p>
				<Row>
					<Col xs={12} lg={4} md={4} xl={4}>
						<Box
							sx={{
								p: 1,
								borderRadius: '10px',
								mt: 2,
							}}
						>
							<p style={{ fontWeight: '600', fontSize: '1.7rem' }}>Сергей</p>
							<p
								style={{
									color: '#7b7b7b',
									fontSize: '12px',
									marginBottom: '5px',
								}}
							>
								19 августа 2024
							</p>
							<Typography component='legend'>Оценка пользователя:</Typography>
							<Rating name='read-only' value={5} readOnly />
							<p>Отличные скутеры за такие деньги</p>
						</Box>
					</Col>
					<Col xs={12} lg={4} md={4} xl={4}>
						<Box
							sx={{
								p: 1,
								borderRadius: '10px',
								mt: 2,
							}}
						>
							<p style={{ fontWeight: '600', fontSize: '1.7rem' }}>Станислав</p>
							<p
								style={{
									color: '#7b7b7b',
									fontSize: '12px',
									marginBottom: '5px',
								}}
							>
								21 августа 2024
							</p>
							<Typography component='legend'>Оценка пользователя:</Typography>
							<Rating name='read-only' value={5} readOnly />
							<p>Нелохие электрические скутеры по доступной цене</p>
						</Box>
					</Col>
					<Col xs={12} lg={4} md={4} xl={4}>
						<Box
							sx={{
								p: 1,
								borderRadius: '10px',
								mt: 2,
							}}
						>
							<p style={{ fontWeight: '600', fontSize: '1.7rem' }}>Андрей</p>
							<p
								style={{
									color: '#7b7b7b',
									fontSize: '12px',
									marginBottom: '5px',
								}}
							>
								21 августа 2024
							</p>
							<Typography component='legend'>Оценка пользователя:</Typography>
							<Rating name='read-only' value={5} readOnly />
							<p>Все отлично рекомендую к покупке</p>
						</Box>
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default TextDescScooter
