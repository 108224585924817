import { Box, Container } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'

const TermOfUse = () => {
	return (
		<Container maxWidth='xl' fluid='md' >
			<Helmet>
				<title>Пользовательское соглашение</title>
				<meta name='description' content='Пользовательское соглашение' />
			</Helmet>
			<h1 style={{ fontWeight: '400' }}>Пользовательское соглашение</h1>
			<Box>
				<p style={{fontSze: '1.7rem'}}>Цели обработки личной информации</p>
				<p>
					Автосалон Ava Drive обрабатывает Вашу личную информацию в соответствии
					с целями, обозначенными в настоящей Политике. Любой случай
					предполагает применение одной или сразу нескольких целей:
				</p>
				<p>
					предоставлять клиентам услуги, показывая содержимое с персонализацией;
				</p>
				<p>
					проверять, исследовать и анализировать информацию, что позволяет
					осуществлять поддержку, защиту и улучшение предоставляемых услуг;
				</p>
				<p>защищать права и собственность нашей компании и клиентов;</p>
				<p>разрабатывать новые продукты/услуги.</p>
				<p>О передаче информации</p>
				<h2>Внесение изменений в настоящую Политику</h2>
				<p>
					Автосалон Ava Drive оставляет за собой право вносить изменения в этот
					документ. Если происходит значительное изменение условий настоящей
					Политики, Автосалон Ava Drive уведомляет пользователей сайта в начале
					данного документа и непосредственно на главной странице сайта.
					Рекомендуем периодически пересматривать условия настоящей Политики для
					ознакомления с внесенными коррективами.
				</p>
			</Box>
		</Container>
	)
}

export default TermOfUse
