import { Box, Divider } from '@mui/material'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import vikyp from './page22.jpg'
const BuyBlock = () => {
	const handleClick = () => {
		window.scrollTo(0, 0)
	}
	return (
		<div>
			<Box className='mt-5'>
				<p className='titleMain'>Вы хотите продать автомобиль?</p>
				<p className='titleTwo'>МЫ ВЫКУПИМ ЕГО</p>
			</Box>
			<Row>
				<Col xs={12} lg={6} md={6} xl={6} className='mainImageBuyBlock'>
					<img
						style={{ borderRadius: '5px', marginTop: '20px' }}
						src={vikyp}
						alt='buy Block'
						className=' imageBuy '
					/>
				</Col>
				<Col
					xs={12}
					lg={6}
					md={6}
					xl={6}
					className='scroll__image '
				>
					<Divider></Divider>
					<Box className='formersdSSe '>
						<p>
							Вы не хотите или не можете самостоятельно продавать автомобиль?
						</p>
						<p>
							Вам требуются деньги, но совсем нет времени на объявления,
							встречи, показы?
						</p>
						<p>У вас есть желание продать автомобиль дорого и без всяких проблем?</p>
						<p>Напишите нам и мы с вами свяжемся</p>
						<ul className='list'>
							<li>Услуга абсолютно бесплатна</li>
							<li>Предпродажная подготовка</li>
							<li>Все кристально чисто</li>
							<li>Вы получите деньги сразу</li>
						</ul>
						<a onClick={handleClick} href='/buy' className='btn2 dopp' style={{ marginTop: '20px' , display: "flex" , alignItems: "center" , justifyContent: "center"}}>
						Оставить заявку
					</a>
					</Box>

					
				</Col>
			</Row>
		</div>
	)
}

export default BuyBlock
