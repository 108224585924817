import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Slide from '@mui/material/Slide'
import Toolbar from '@mui/material/Toolbar'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import PropTypes from 'prop-types'
import * as React from 'react'
import { FaOdnoklassniki, FaPhone, FaWhatsapp } from 'react-icons/fa'
import { GiPositionMarker } from 'react-icons/gi'
import { HiOutlineMenu } from 'react-icons/hi'
import { IoMdClose } from 'react-icons/io'
import { IoMailSharp, IoTimeSharp } from 'react-icons/io5'
import { SlSocialVkontakte } from 'react-icons/sl'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { logout, selectIsAuth } from '../../store/slices/auth'
import ModalPhone from '../Modal/modal-phone'
import logoAvaDrive from './12.png'
function HideOnScroll(props) {
	const { children, window } = props

	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
	})

	return (
		<Slide appear={false} direction='down' in={!trigger}>
			{children}
		</Slide>
	)
}

HideOnScroll.propTypes = {
	children: PropTypes.element.isRequired,

	window: PropTypes.func,
}

export default function Header(props) {
	const isAuth = useSelector(selectIsAuth)
	const [isShow, setIsShow] = React.useState(false)
	const dispatch = useDispatch()

	const handleClick = event => {
		setIsShow(current => !current)
	}
	const onClickLogout = () => {
		if (
			window.confirm(
				'Вы действительно хотите выйти из аккаунта Администратора?'
			)
		) {
			dispatch(logout())
			window.localStorage.removeItem('token')
		}
	}

	return (
		<header>
			<AppBar className='headerWrapper' position='static'>
				<Box
					sx={{
						display: { xs: 'none', md: 'flex' },
						justifyContent: 'space-between',
						alignItems: 'center',
						padding: '0 20px',
						backdropFilter: 'saturate(180%) blur(5px);',
					}}
				>
					<Box sx={{ display: 'contents' }}>
						<Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
							<FaPhone size={25} />
							<a href='tel:+79014597662'>+7 (901) 459-7662</a>
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
							<IoMailSharp size={25} />
							<span>ava-drive@mail.ru</span>
						</Box>

						<Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
							<IoTimeSharp size={25} />
							<span>Пн-Вс с 9:00 до 19:00</span>
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
							<GiPositionMarker size={25} />
							<span>г. Барнаул Павловский тракт 313/2</span>
						</Box>
					</Box>
					<Box>
						<span>Наши социальные сети</span>
						<Box className='social-media'>
							<a
								href='https://vk.com/avadrive22'
								target='_blank'
								rel='noreferrer'
							>
								<SlSocialVkontakte />
							</a>
							<a
								href='https://ok.ru/profile/605797506585'
								target='_blank'
								rel='noreferrer'
							>
								<FaOdnoklassniki />
							</a>
							<a
								rel='noreferrer'
								href='https://auto.drom.ru/ava-drive/'
								target='_blank'
							>
								Д
							</a>
							<a
								href='https://wa.me/+79014598406'
								target='_blank'
								rel='noreferrer'
							>
								<FaWhatsapp />
							</a>
							<ModalPhone />
						</Box>
					</Box>
				</Box>
				<Toolbar className='headerContainer'>
					<Box>
						<Link to='/'>
							{/* <Typography
									variant='h6'
									component='div'
									sx={{
										fontFamily: "'Bebas Neue', sans-serif",
										fontSize: '2.25rem',
										
									}}
								>
									Ava Drive
								</Typography> */}
							<img
								src={logoAvaDrive}
								alt='logo'
								className='logoHeader'
								width={80}
							/>
						</Link>
					</Box>
					<div className='burgerBox' onClick={handleClick}>
						{isShow ? <IoMdClose size={40} /> : <HiOutlineMenu size={40} />}
					</div>
					<Box>
						{isShow && (
							<Box className='burgerMenuBox'>
								<Box className='burgerMenuBoxWr'>
									<Link onClick={handleClick} to='/about'>
										О нас
									</Link>
									<Link onClick={handleClick} to='/contacts'>
										Контакты
									</Link>
									<a onClick={handleClick} href='/search/withmileage'>
										Поиск автомобиля
									</a>

									<Link onClick={handleClick} to='/reviews'>
										Отзывы
									</Link>
									<Link to='/buy' onClick={handleClick}>
										Выкуп вашего авто
									</Link>
									<Box sx={{ display: 'contents' }}>
										<Box>
											<FaPhone size={20} style={{ marginRight: '10px' }} />
											<a href='tel:+79014597662'>+7 (901) 459-7662</a>
										</Box>
										<Box>
											<IoMailSharp size={20} style={{ marginRight: '10px' }} />
											<span>ava-drive@mail.ru</span>
										</Box>

										<Box>
											<IoTimeSharp size={20} style={{ marginRight: '10px' }} />

											<span>Пн-Вс с 9:00 до 19:00</span>
										</Box>
										<Box>
											<GiPositionMarker
												size={20}
												style={{ marginRight: '10px' }}
											/>

											<span>Павловский тракт 313/2</span>
										</Box>
									</Box>
									<span>Наши социальные сети</span>
									<Box className='social-media'>
										<a
											href='https://vk.com/avadrive22'
											target='_blank'
											rel='noreferrer'
										>
											<SlSocialVkontakte />
										</a>
										<a
											href='https://ok.ru/profile/605797506585'
											target='_blank'
											rel='noreferrer'
										>
											<FaOdnoklassniki />
										</a>
										<a
											rel='noreferrer'
											href='https://auto.drom.ru/ava-drive/'
											target='_blank'
										>
											Д
										</a>
										<a
											href='https://wa.me/+79014598406'
											target='_blank'
											rel='noreferrer'
										>
											<FaWhatsapp />
										</a>
									</Box>
									{isAuth ? (
										<Link
											to='/add-post'
											className='adminLink'
											onClick={handleClick}
										>
											Добавить машину
										</Link>
									) : (
										''
									)}
									{isAuth ? (
										<Link
											onClick={onClickLogout}
											to='#'
											className='adminLinkExit'
										>
											Выход
										</Link>
									) : (
										''
									)}
								</Box>
							</Box>
						)}
					</Box>
					<Box
						className='headerLinksWrapper navbar'
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<Link to='/about'>О нас</Link>
						<Link to='/reviews'>Отзывы</Link>
						<Link to='/contacts'>Контакты</Link>
						<a href='/search/withmileage'>Поиск автомобиля</a>

						<Link to='/buy'>Выкуп вашего авто</Link>
						{isAuth ? (
							<Link to='/add-post' className='adminLink'>
								Добавить машину
							</Link>
						) : (
							''
						)}
						{isAuth ? (
							<Link onClick={onClickLogout} to='#' className='adminLinkExit'>
								Выход
							</Link>
						) : (
							''
						)}
					</Box>
				</Toolbar>
			</AppBar>

			<Toolbar />
		</header>
	)
}
