import { Box } from "@mui/material"
import React from "react"
import { Col, Row } from "react-bootstrap"
import img1 from "../../img/1.png"
import img2 from "../../img/2.png"
import img3 from "../../img/3.png"
import img4 from "../../img/4.png"

const Blog = () => {
  return (
    <section className="sectionBlog">
      <Box >
        <Box sx={{ mb: 4 }}>
          <p className="titleMain">Мы предлагаем только</p>
          <p className="titleTwo">ПРОВЕРЕННЫЕ АВТОМОБИЛИ И КВАДРОЦИКЛЫ</p>
        </Box>
        <Box>
          <Row className="SectionTwoWorkDev">
            <Col xs={12} lg={6} md={6} xl={3}>
              <img src={img1} alt="word" />
              <p>Наши специалисты помогут подобрать автомобиль или квадроцикл</p>
            </Col>
            <Col xs={12} lg={6} md={6} xl={3}>
              <img src={img3} alt="word" />
              <p>Все автомобили и квадроциклы юридически чисты</p>
            </Col>
            <Col xs={12} lg={6} md={6} xl={3}>
              <img src={img4} alt="word" />
              <p>Мы предоставляем всю информацию о автомобиле и квадроцикле</p>
            </Col>
            <Col xs={12} lg={6} md={6} xl={3}>
              <img src={img2} alt="word" />
              <p>Наши автомобили и квадроциклы не имеют скрытых дефектов</p>
            </Col>
          </Row>
        </Box>
      </Box>
    </section>
  );
};

export default Blog;
