import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    itemsInCart: [],
    totalPrice: 0,
  },
  reducers: {
    setItemInCart: (state, action) => {
      const findItem = state.itemsInCart.find(
        (game) => game._id === action.payload._id
      );
      if (findItem) {
        findItem.count++;
      } else {
        state.itemsInCart.push({ ...action.payload, count: 1 });
      }
      state.totalPrice = state.itemsInCart.reduce((sum, game) => {
        return game.price * game.count + sum;
      }, 0);
    },
    deleteItemFromCart: (state, action) => {
      state.itemsInCart = state.itemsInCart.filter(
        (game) => game._id !== action.payload
      );
    },
    countMinusItem: (state, action) => {
      const findItem = state.itemsInCart.find(
        (game) => game._id === action.payload
      );

      if (findItem) {
        findItem.count--;
      }
      state.totalPrice = state.itemsInCart.reduce((sum, game) => {
        return game.price * game.count + sum;
      }, 0);
    },

    countItemInCart: (state, action) => {
      state.itemsInCart.push(action.payload);
    },
    clearCart: (state, action) => {
      state.itemsInCart = [];
      state.totalPrice = 0;
    },
  },
});

export const {
  setItemInCart,
  deleteItemFromCart,
  countItemInCart,
  countMinusItem,
  clearCart,
} = cartSlice.actions;
export default cartSlice.reducer;
