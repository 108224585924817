import { Box, Container } from '@mui/material'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import axios from '../../axios'
import img1 from '../../img/icons/1.png'
import img2 from '../../img/icons/2.png'
import img3 from '../../img/icons/3.png'
import img4 from '../../img/icons/4.png'
import { notify } from '../../pages/home-page/Home'
import { selectIsAuth } from '../../store/slices/auth'
import BackImg from '../home-page/back.jpg'
import BuyBlock from './BuyBlock'
import car from './car.png'

const Buy = () => {
	const navigate = useNavigate()
	const [formValid, setFormValid] = React.useState(false)

	const isAuth = useSelector(selectIsAuth)

	const [inputValue, setInputValue] = React.useState({
		name: '',
		phone: '',
		car: '',
	})

	const handleSubmit = async event => {
		event.preventDefault()
		const trgt = event.target

		if (!isAuth) {
			try {
				const result = await axios.post('/order', {
					name: trgt.name.value,
					phone: trgt.phone.value,
					car: trgt.car.value,
				})

				navigate('/')

				notify()
			} catch (e) {
				console.error(e)
			}
		}
	}
	const changeHandler = ({ currentTarget }) => {
		const { value, name } = currentTarget
		setInputValue(prev => {
			const formValues = { ...prev, [name]: value }
			validate(formValues)
			return formValues
		})
		const validate = ({ name, phone }) => {
			const nameIsValid = name
			const phoneIsValid = phone
			const isCredentialsValid = nameIsValid && phoneIsValid
			if (isCredentialsValid !== formValid) setFormValid(isCredentialsValid)
		}
	}

	return (
		<>
		<Helmet>
						<title>Выкуп вашего авто</title>
						<meta
							name='description'
							content='Задумываясь о продаже своего автомобиля, каждый из нас всегда хочет получить как можно больше денег, не тратя на это свое время и силы. AVA DRIVE решит это вопрос лучше всех.'
						/>
					</Helmet>
			<Container maxWidth='xl' >
				<Box className='presentation'>
					<img
						src={BackImg}
						alt='background'
						width={100}
						className='imgFrontInBuy'
					/>
				</Box>
				<Row className='wrapperBuy'>
					<Col xs={12} lg={6} md={8} xl={8}>
						<h1 style={{ fontWeight: '400' }}>AVA DRIVE выкупит ваш автомобиль</h1>
					</Col>
					<Col xs={12} lg={6} md={4} xl={4} className='colBuy'>
						<form onSubmit={handleSubmit}>
							<Box className=' mt-3'>
								<p
									className='mb-4'
									style={{ textAlign: ' center', fontSize: '1.4rem', fontWeight: '200' }}
								>
									Оставьте заявку и мы перезвоним Вам
								</p>
								<input
									className='inputLogin mb-3'
									value={inputValue.name}
									placeholder='Введите имя'
									type='text'
									name='name'
									onChange={changeHandler}
								/>
								<input
									className='inputLogin mb-3'
									value={inputValue.phone}
									placeholder='Введите номер телефона'
									type='text'
									name='phone'
									onChange={changeHandler}
								/>
								<input
									className='inputLogin mb-3'
									value={inputValue.car}
									placeholder='Введите марку и год машины'
									type='text'
									name='car'
									onChange={changeHandler}
								/>

								<Box>
									<button
										style={{ width: '100%' }}
										disabled={!formValid}
										type='submit'
										className='btn2 dopp'
									>
										Отправить
									</button>
								</Box>
							</Box>
						</form>
					</Col>
				</Row>
			</Container>

			<Container maxWidth='xl'>
				<section>
					
					<Box >
						<Box sx={{ mb: '30px', mt: '70px' }}>
							<p className='titleMain'>C AVA DRIVE </p>
							<p  className='titleTwo'>ЛЕГКО И ПРОСТО</p>
						</Box>
						<Box>
							<Row className='SectionTwoWorkDev'>
								<Col xs={12} lg={6} md={6} xl={3}>
									<img src={img1} alt='example car' width={70} />
									<p>Выплата всей стоимости вашего авто на месте</p>
								</Col>
								<Col xs={12} lg={6} md={6} xl={3}>
									<img src={img2} alt='example car' width={70} />

									<p>Быстрая оценка вашего автомобиля</p>
								</Col>
								<Col xs={12} lg={6} md={6} xl={3}>
									<img src={img3} alt='example car' width={70} />

									<p>Выкупим автомобиль по рыночной цене</p>
								</Col>
								<Col xs={12} lg={6} md={6} xl={3}>
									<img src={img4} alt='example car' width={70} />

									<p>Оформление в течение 30 минут</p>
								</Col>
							</Row>
						</Box>
					</Box>
				</section>

				<section className='sectionVhh' style={{ position: 'relative' }}>
					<Box className='mt-3'>
						{' '}
						<Box sx={{ mb: '30px', mt: '40px' }}>
							<p className='titleMain'>Простой и надежный </p>
							<p className='titleTwo'>АЛГОРИТМ РАБОТЫ</p>
						</Box>
						<Box>
							<Row>
								<Col xs={12} lg={12} md={12} xl={4}>
									<Row>
										<Col xs={1} lg={1} md={1} xl={1}>
											<p style={{ fontSize: '2.5rem', fontWeight: '200',color : ' rgb(123, 123, 123)'}}>1</p>
										</Col>
										<Col xs={11} lg={11} md={11} xl={11}>
											<p className="titleMain">Оформите заявку</p>
											<p>Позвоните или напишите нам</p>
										</Col>
										<Col xs={1} lg={1} md={1} xl={1}>
											<p style={{ fontSize: '2.5rem', fontWeight: '200',color : ' rgb(123, 123, 123)'}}>2</p>
										</Col>
										<Col xs={11} lg={11} md={11} xl={11}>
											<p className="titleMain">Оценим автомобиль</p>
											<p>Оценим авто за 30 минут</p>
										</Col>
										<Col xs={1} lg={1} md={1} xl={1}>
											<p style={{ fontSize: '2.5rem', fontWeight: '200',color : ' rgb(123, 123, 123)'}}>3</p>
										</Col>
										<Col xs={11} lg={11} md={11} xl={11}>
											<p className="titleMain">Получите деньги</p>
											<p>
												Выплачиваем всю стоимость за ваш автомобиль в момент
												подписания договора
											</p>
										</Col>
									</Row>
								</Col>

								<Col xs={12} lg={12} md={12} xl={8}>
									<img
										src={car}
										alt=''
										className='carImageBack scroll__image wow  animate__animated animate__fadeInRight animate__delay-1s'
									/>
								</Col>
							</Row>
						</Box>
					</Box>
				</section>
				<BuyBlock />
			</Container>
		</>
	)
}

export default Buy
