import axios from 'axios'

const instance = axios.create({
	baseURL: 'https://ava-drive.ru/api/',
	// baseURL: 'http://localhost:4444',
})

instance.interceptors.request.use(config => {
	config.headers.Authorization = window.localStorage.getItem('token')
	return config
})

export default instance

// export const linkDev = 'http://localhost:4444'
export const linkDev = 'https://ava-drive.ru/'
