import { Box, Container } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet'

const PrivacyPolicy = () => {
	return (
		<Container maxWidth='xl' fluid='md' >
			<Helmet>
				<title>Политика конфиденциальности</title>
				<meta name='description' content='Политика конфиденциальности' />
			</Helmet>
			<h1 style={{ fontWeight: '400' }}>Политика конфиденциальности</h1>

			<Box>
				<p>
					Настоящая Политика о конфиденциальности и защите личной информации
					(далее — Политика) относится к сайту www.ava-drive.ru (далее — сайт).
				</p>
				<p>
					Пользуясь этим сайтом или/и передавая Автосалон Ava Drive личные
					сведения, Вы соглашаетесь на их обработку в рамках условий, которые
					предусматривает настоящая Политика.
				</p>
				<p>
					В случае несогласия с условиями настоящей Политики рекомендуется не
					прибегать к использованию этого сайта и не отправлять Автосалон Ava
					Drive свои личные сведения.
				</p>
				<p>
					Автосалон Ava Drive с уважением относится к неприкосновенности частной
					жизни и осознает, насколько важно обеспечивать полную
					конфиденциальность и безопасность личной информации.
				</p>
				<p>
					Выражаем надежду, что данный документ позволит Вам разобраться в том,
					какого типа сведения собираются и как происходит их обработка.
					Документ имеет прямое отношение к сведениям, поступившим в результате
					получения предоставляемых Автосалон Ava Drive услуг/продуктов и другим
					вариантам взаимодействия. Для ряда услуг/продуктов возможна публикация
					доп. документов о конфиденциальности.
				</p>
				<p>
					В описании услуг/продуктов Автосалон Ava Drive могут содержаться
					ссылки на веб-ресурсы других фирм и услуги 3-х лиц, чья политика
					конфиденциальности может отличаться от нашей. За политику и содержимое
					подобных веб-ресурсов Автосалон Ava Drive ответственным не является.
				</p>
				<p style={{fontSze: '1.7rem'}}>Собираемые сведения</p>
				<p>
					Сбор Вашей информации стандартно происходит в момент приобретения,
					пользования нашими услугами или регистрации с целью использования
					услуг, а также участия в действующих акциях и при других вариантах
					взаимодействия с Автосалон Ava Drive.
				</p>
				<p>
					Без Вашего согласия сбор личных сведений не производится. Далее
					приведем примеры видов подобной информации.
				</p>
				<p>
					Автосалон Ava Drive обрабатывает Вашу личную информацию в соответствии
					с целями, обозначенными в настоящей Политике. Любой случай
					предполагает применение одной или сразу нескольких целей.
				</p>
				<p style={{fontSze: '1.7rem'}}>О Ваших правах</p>
				<p>
					Если Вам надо знать, какого рода личными сведениями мы обладаем, либо
					Вы нуждаетесь в дополнении, исправлении, обезличивании или удалении
					любых неточных, неполных или устаревших личных данных, либо Вам нужно,
					чтобы мы перестали обрабатывать Вашу персональную информацию в
					рекламных целях, либо в случае наличия иных законных оснований, Вы
					можете это реализовать, согласно действующему законодательству. Для
					этого обращайтесь к нам по указанному ниже адресу.
				</p>
				<p>
					Некоторые случаи (например, удаление или прекращение обработки Ваших
					личных сведений) предполагают тот факт, что мы больше не будем иметь
					возможность предоставления Вам наших услуг.
				</p>
				<p>
					Чтобы выполнить подобные Ваши запросы Автосалон Ava Drive, возможно,
					понадобится установление Вашей личности вкупе с дополнительными
					сведениями. Помимо этого, в рамках действующих законов могут
					устанавливаться ограничения и иные условия относительно Ваших
					вышеупомянутых прав.
				</p>
			</Box>
		</Container>
	)
}

export default PrivacyPolicy
