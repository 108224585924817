import { Box, Rating, Typography } from '@mui/material'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

const TextDesc = () => {
	return (
		<div>
			<p>
				Квадроциклы — это не только экстремальное времяпрепровождение, но и
				незаменимый транспорт на природе для взрослых и подростков. Никакая
				машина не будет настолько мобильна на российской пересеченной местности
				как квадроцикл. Также езда на квадроцикле — отличный способ поддерживать
				физическую активность, вас будут сопровождать чувство азарта, адреналина
				и масса положительных эмоций.
			</p>

			<div>
				<p className='titleMain'>Особенности и преимущества квадроциклов</p>
				<ul>
					<li>
						Согласно закону, права на квадроцикл можно получить с 16 лет. Это
						значит, даже несовершеннолетний подросток может испытать на себе
						бурю эмоций от передвижения на данном виде транспорта;
					</li>
					<li>
						Электрические квадроциклы с мощностью двигателя до 4000 W и
						бензиновые с объемом двигателя до 50 см.куб не требуют регистрации;
					</li>
					<li>Как ничто другое подходит для передвижения по бездорожью;</li>
					<li>
						Данный вид транспорта отличается легкостью в управлении и
						манёвренностью, что дает возможность быстро освоиться даже новичкам
					</li>
					<li>
						Габариты квадроцикла, в среднем, 120х120 см — транспорт не займет
						много места при хранении.
					</li>
				</ul>

				<p className='titleMain'>Почему стоит купить квадроциклы у нас</p>
				<ul>
					<li>Подтвержденное качество</li>
					<li>Доступные цены</li>
					<li>Гарантия</li>
				</ul>
			</div>
			<div>
				<p className='titleMain'>Как сделать заказ</p>
				<ul>
					<li>Нажать на кнопку "Купить"</li>
					<li>Заполните необходимые поля и оформите заявку </li>
					<li>
						Наши менеджеры перезвонят для подтверждения заказа и
						проконсультируют по всем вопросам!
					</li>
				</ul>
			</div>
			<div>
				<p className='titleMain'>Отзывы наших покупателей</p>
				<Row>
					<Col xs={12} lg={4} md={4} xl={4}>
						<Box
							sx={{
								p: 1,
								borderRadius: '10px',
								mt: 2,
							}}
						>
							<p style={{ fontWeight: '600', fontSize: '1.7rem' }}>Дима</p>
							<p
								style={{
									color: '#7b7b7b',
									fontSize: '12px',
									marginBottom: '5px',
								}}
							>
								19 мая 2024
							</p>
							<Typography component='legend'>Оценка пользователя:</Typography>
							<Rating name='read-only' value={5} readOnly />
							<p>
								Квадроциклы которые предоставлены в автосалоне, действительно
								качественные, подходят для любого типа движения. Купил и не
								пожалел 5 звезд
							</p>
						</Box>
					</Col>
					<Col xs={12} lg={4} md={4} xl={4}>
						<Box
							sx={{
								p: 1,
								borderRadius: '10px',
								mt: 2,
							}}
						>
							<p style={{ fontWeight: '600', fontSize: '1.7rem' }}>Владислав</p>
							<p
								style={{
									color: '#7b7b7b',
									fontSize: '12px',
									marginBottom: '5px',
								}}
							>
								22 июня 2024
							</p>
							<Typography component='legend'>Оценка пользователя:</Typography>
							<Rating name='read-only' value={5} readOnly />
							<p>
								Действительно квадроциклом очень доволен, покупал в этом
								автосалоне, не пожалел и не буду жалеть, очень качественные,
								передвигаюсь по бору с сыном, мы очень довольны
							</p>
						</Box>
					</Col>
					<Col xs={12} lg={4} md={4} xl={4}>
						<Box
							sx={{
								p: 1,
								borderRadius: '10px',
								mt: 2,
							}}
						>
							<p style={{ fontWeight: '600', fontSize: '1.7rem' }}>Никита</p>
							<p
								style={{
									color: '#7b7b7b',
									fontSize: '12px',
									marginBottom: '5px',
								}}
							>
								11 июня 2024
							</p>
							<Typography component='legend'>Оценка пользователя:</Typography>
							<Rating name='read-only' value={5} readOnly />
							<p>
								Сегодня приобрел квадроцикл в ава драй, помогли с выбором,
								менеджеры - лучшие, подсказали и рассказали про каждый
								представленный квадроцикл
							</p>
						</Box>
					</Col>
				</Row>
			</div>
		</div>
	)
}

export default TextDesc
