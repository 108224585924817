import { Box } from '@mui/material'
import { Container } from '@mui/system'
import React, { Component } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import place from './avadrive.jpg'
import speed from './speed.png'

export class AboutUs extends Component {
	render() {
		return (
			<>
				<Container maxWidth='xl' fluid='md'>
					<section className='home-next'>
						<Helmet>
							<title>О нас</title>
							<meta name='description' content='AVA DRIVE - честный и быстрорастущий автосалон в городе Барнаул. Компания предлагает широкий ассортимент легковых автомобилей иностранного производства, а так же квадроциклов.' />
						</Helmet>
						<Box className='aboutUsBlock   home-content-full'>
							<Box sx={{ mb: 2 }} className='text-df'>
								<h1 style={{ fontWeight: '400' }}>О нас</h1>

								<Row>
									<Col xs={12} lg={6} md={6} xl={6}>
										<p>
											AVA DRIVE - честный и быстрорастущий автосалон в городе
											Барнаул. Компания предлагает широкий ассортимент легковых
											автомобилей иностранного производства, а так же
											квадроциклов. Осуществляет разнообразный спектр услуг:
											продажа, срочный выкуп автомобилей с пробегом, оценка и
											обмен, автокредитование, покупупка квадроцикла.
										</p>
										<p>
											Основными принципами в работе компании являются:
											ориентация на качественное удовлетворение потребностей
											клиентов, профессионализм в работе и порядочность.
										</p>
									</Col>
									<Col xs={12} lg={6} md={6} xl={6}>
										{' '}
										<Box>
											<img src={speed} alt='PLACE' className='placeImg' />
										</Box>
									</Col>
								</Row>

								<Row style={{ marginTop: '20px' }}>
									<Col xs={12} lg={6} md={6} xl={6}>
										<p style={{ marginTop: '20px' }}>
											МЫ РАДЫ ПРЕДЛОЖИТЬ ВАМ АВТОМОБИЛИ И КВАДРОЦИКЛЫ ПО ЧЕСТНЫМ
											ЦЕНАМ БЕЗ НАЦЕНОК И СКРЫТЫХ КОМИССИЙ.
										</p>
										<p>
											Все автомобили и квадроциклы проходят проверку на
											юридическую чистоту, и мы гарантируем отсутствие
											каких-либо проблем с документами. Выберите автомобиль или
											квадроцикл, который подходит вам по всем параметрам, и
											свяжитесь с нами для обсуждения условий покупки.
										</p>
										<p>
											МЫ ВСЕГДА ГОТОВЫ ПОМОЧЬ ВАМ С ВЫБОРОМ И ОТВЕТИТЬ НА ВСЕ
											ВАШИ ВОПРОСЫ!
										</p>
									</Col>
									<Col xs={12} lg={6} md={6} xl={6}>
										<Box>
											<img src={place} alt='PLACE' className='placeImg' />
										</Box>
									</Col>
								</Row>
							</Box>
						</Box>
					</section>
				</Container>
			</>
		)
	}
}
