import React from 'react'
import { Helmet } from 'react-helmet'

const NotFound = () => {
	return (
		<div style={{ textAlign: 'center' }}>
			<Helmet>
					<title>404 - Страница не найдена</title>
					<meta name='description' content='Страница не найдена' />
				</Helmet>
			<p style={{fontSize: '100px'}}>😩</p>
			<h1>404 - Страница не найдена</h1>
			<p>Извините, но запрашиваемая страница не существует. В процессе обработки вашего запроса произошла ошибка.</p>
			<a className='btn2' href='/'>Вернуться на главную страницу</a>
		</div>
	)
}

export default NotFound
