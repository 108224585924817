import { Box, Divider } from '@mui/material'
import { Container } from '@mui/system'
import React, { useState } from 'react'
import { Carousel, Col, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { linkDev } from '../../axios'
import { Card } from '../../components/Card/Card'
import ModalPhone from '../../components/Modal/modal-phone'
import Reviews from '../../components/Reviews/Reviews'
import avadrive from '../../img/avadrive.png'
import { fetchPosts } from '../../store/slices/posts'
import BackImg from './back.jpg'
import Blog from './Blog'
import BuyBlock from './BuyBlock'
import gg from './gg.jpg'
import MapBlog from './MapBlog'
import risk from './risk.jpg'
import risk3 from './risk3.jpg'
import whiteCar from './whiteCar.png'

export const notify = () =>
	toast.success('Заявка принята, ожидайте звонка.', {
		position: 'top-right',
		autoClose: 3000,
		hideProgressBar: true,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: 'dark',
	})

const Home = () => {
	const userData = useSelector(state => state.auth.data)
	const { posts } = useSelector(state => state.posts)
	const dispatch = useDispatch()
	const [data, setData] = useState(posts.items)
	const isPostsLoading = posts.status === 'loading'

	React.useEffect(() => {
		window.scrollTo(0, 0)
		dispatch(fetchPosts())
	}, [dispatch])

	React.useEffect(() => {
		setData(posts.items)
	}, [posts.items])

	return (
		<header>
			<Container maxWidth='xl' fluid='md'>
				<Helmet>
					<title>Автосалон Ava Drive </title>
					<meta name='description' content='Купить автомобиль без пробега в Барнауле, купить автомобиль с пробегом в Барнауле, купить квадроцикл в Барнауле, купить Скутер в Барнауле по выгодной цене' />
				</Helmet>
				<Box className='presentation dopset'>
					<img src={BackImg} alt='' width={100} />
				</Box>
				<Box sx={{ marginTop: '30px' }}>
					<Box>
						<ToastContainer
							position='top-right'
							autoClose={3000}
							hideProgressBar
							newestOnTop={false}
							closeOnClick
							rtl={false}
							pauseOnFocusLoss
							draggable
							pauseOnHover
							theme='dark'
						/>
					</Box>

					<section className='home' style={{ position: 'relative' }}>
						<img
							src={whiteCar}
							alt='white car'
							className='carImageBack mainCarDop carImageBack mainCarDop  scroll__image wow  animate__animated animate__fadeInRight animate__delay-1s'
						/>
						<Box className='home-content'>
							<Box
								className='homePhoneNumber'
								sx={{ display: { md: 'none', xs: 'block' }, mt: 3 }}
							>
								<ModalPhone />
							</Box>

							<p style={{ marginTop: '50px' }} className='mainn'>
								Ваш лучший выбор
							</p>
							<h1 className='titleMain' style={{ fontWeight: '400' }}>
								Ava Drive
							</h1>
							<p>Продажа автомобилей с пробегом и квадроциклов в г.Барнаул</p>
							<Box></Box>
						</Box>
					</section>
					<Divider></Divider>

					<Box className='mt-5'>
						<p className='titleMain'>Автомобили и квадроциклы в наличии</p>
					</Box>

					<Box sx={{ marginTop: '10px' }}>
						{isPostsLoading ? (
							<Row>
								<Col xs={12} lg={6} md={6} xl={3}>
									<Card isLoading={true} />
								</Col>
								<Col xs={12} lg={6} md={6} xl={3}>
									<Card isLoading={true} />
								</Col>
								<Col xs={12} lg={6} md={6} xl={3}>
									<Card isLoading={true} />
								</Col>
								<Col xs={12} lg={6} md={6} xl={3}>
									<Card isLoading={true} />
								</Col>
							</Row>
						) : (
							''
						)}
						<Row>
							{data.slice(7, 11).map((obj, k) =>
								isPostsLoading ? (
									<Col xs={12} lg={6} md={6} xl={3} key={k}>
										<Card isLoading={true} />
									</Col>
								) : (
									<Col
										xs={12}
										lg={6}
										md={6}
										xl={3}
										key={k}
										className='cardCarHomePage'
									>
										<Box>
											<Card
												maximumSpeed={obj.maximumSpeed}
												maximumLoad={obj.maximumLoad}
												volumePower={obj.volumePower}
												engineBike={obj.engineBike}
												tags={obj.tags}
												key={k}
												_id={obj._id}
												title={obj.title}
												imageUrl={
													obj.imageUrl ? `${linkDev}${obj.imageUrl}` : avadrive
												}
												price={obj.price}
												isEditable={userData?._id === obj.user._id}
												game={obj}
												transmission={obj.transmission}
												mileage={obj.mileage}
												engine={obj.engine}
												power={obj.power}
												category={obj.category}
											/>
										</Box>
									</Col>
								)
							)}
						</Row>
						<Box
							sx={{
								margin: '30px 0',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<a
								href='/search/withmileage'
								className='btn2 dopp actualOffer'
								style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
								}}
							>
								Посмотреть актуальные предложения
							</a>
						</Box>
					</Box>

					<section className='sectionVh'>
						<Box>
							<Row style={{ position: 'relative' }}>
								<Col xs={12} lg={12} md={12} xl={4} className='textBlockPhone'>
									<Box>
										<p style={{ fontSize: '1.4rem', fontWeight: '200' }}>
											При покупке б/у авто у частного лица Вы рискуете!
										</p>
										<div className='textBlock'>
											<p>
												Продавцы зачастую предоставляют ложную информацию о
												техническом состоянии автомобиля, уверяя в его полной
												исправности.
											</p>
											<p>
												Чтобы полностью оценить состояние автомобиля, зачастую
												приходится тратить очень много времени, денег и нервов,
												а также изучение его истории и отсутствие всевозможных
												запретов и проблем с документами. Из-за этого процесс
												приобретения автомобиля превращается из приятной покупки
												в абсолютную рутину.
											</p>
											<p>
												В автосалоне AVA DRIVE, мы предлагаем только технически
												исправные и юридически чистые автомобили и квадроциклы,
												с гарантией! Покупая автомобиль или квадроцикл у нас, Вы
												избавляете себя от проблем, которые могут появиться при
												покупке у частных лиц в будущем, экономя деньги, время,
												и собственные нервы!
											</p>
										</div>
									</Box>
								</Col>
								<Col xs={12} lg={12} md={12} xl={8} className=''>
									<Carousel className='crsl'>
										<Carousel.Item interval={2500}>
											<img
												src={risk}
												alt='white car'
												className=' imgdownSlide '
											/>
										</Carousel.Item>

										<Carousel.Item interval={2500}>
											<img
												src={gg}
												alt='white car'
												className=' imgdownSlide '
											/>
										</Carousel.Item>
										<Carousel.Item interval={2500}>
											<img
												src={risk3}
												alt='white car'
												className=' imgdownSlide '
											/>
										</Carousel.Item>
									</Carousel>
								</Col>
							</Row>
						</Box>
					</section>

					<Blog />
					<BuyBlock />
					<Reviews />
					<MapBlog />
				</Box>
			</Container>
		</header>
	)
}

export default Home
