import React from "react";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";

import styles from "./Card.module.scss";
import { Box } from "@mui/material";

export const PostSkeleton = () => {
  return (
    <Box className={styles.skeleton}>
      <Stack spacing={1}>
        <Skeleton variant="rectangular" width="100%" height={230} />
        <Box className={styles.skeletonContent}>
          <Box>
            <Box>
              <Skeleton variant="text" width="100%" height={30} />
              <Skeleton variant="text" width="100%" height={30} />
              <Skeleton variant="text" width="70%" height={20} />
              <Skeleton variant="text" width="70%" height={20} />
              <Skeleton variant="text" width="70%" height={20} />
              <Skeleton variant="text" width="70%" height={20} />
            </Box>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};
