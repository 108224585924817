import { Avatar, Box, Container, Rating, Typography } from '@mui/material'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import axios from '../../axios'
import ModalReviews from '../../components/Modal/modal-reviews'
const Reviews = () => {

	const [value, setValue] = React.useState(5);
	const [rewiews, setReviews] = React.useState([]);
	React.useEffect(() => {
		
    axios.get(`/comments`)
        .then(res => setReviews(res.data))
        .catch(err => console.warn(err));
			
}, [setReviews]);



	return (
		<Container maxWidth='xl' fluid='md' >
			<Helmet>
				<title>Отзывы</title>
				<meta name='description' content='Отзывы наших покупателей' />
			</Helmet>
			<h1 style={{  fontWeight: '400' }}>Отзывы наших покупателей</h1>
			<Box sx={{ display: 'flex', justifyContent: 'start', mt: 2, mb: 2 }}>
					<ModalReviews/>
				</Box>
			<Row>
			{rewiews.map(review => (
							<Col xs={12} lg={4} md={4} xl={4} key={review.text}>
							<Box
								sx={{
									p: 3,
									borderRadius: '10px',
									mt: 2,
									background: '#1a1a1a85',
								}}
							>
								<Avatar alt='Remy Sharp' sx={{ width: 50, height: 50,mb:1 }} />
								<p style={{ fontWeight: '600', fontSize: '1.3rem', margin: '0' }}>{review.name}</p>
								{/* <p style={{ margin:"0", color: '#7b7b7b', fontSize: '12px' }}>12.12.2023</p> */}
								<p>
									{review.text}
								</p>
								<Typography component='legend'>Оценка пользователя</Typography>
								<Rating name='read-only' value={value} readOnly />
							</Box>
						</Col>
					))}
				
			</Row>
		</Container>
	)
}

export default Reviews
