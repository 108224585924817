import React from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { Navigate } from "react-router-dom"
import { fetchAuth, selectIsAuth } from "../../store/slices/auth"
import styles from "./Login.module.scss"

import { Box, Divider, Typography } from "@mui/material"

export const Login = () => {
  const isAuth = useSelector(selectIsAuth);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,

    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });
  const longerBox = document.querySelector(".logreg-box");

  const registerLink = document.querySelector(".register-link");
  const onSubmit = async (values) => {
    const data = await dispatch(fetchAuth(values));

    if (!data.payload) {
      return alert("Не удалось авторизоваться");
    }
    if ("token" in data.payload.user) {
      window.localStorage.setItem("token", data.payload.user.token);
    } else {
      alert("Не удалось авторизоваться");
    }
  };

  if (isAuth) {
    return <Navigate to="/" />;
  }
  if (registerLink) {
    registerLink.addEventListener("click", () => {
      longerBox.classList.add("active");
    });
  }

  return (
    <Box className={styles.root} sx={{ width: { md: "500px", sm: "100%" } }}>
      <Box className={styles.LoginBlock}>
        <Typography
          sx={{
            fontSize: "24px",
            textAlign: "center",
            mt: 2,
            mb: 2,
          
          }}
          variant="p"
        >
          Вход в панель управления
        </Typography>
        <Divider sx={{ mb: 2 }}></Divider>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            placeholder="Логин"
            className="inputLogin mb-3"
            label="E-Mail"
            type="email"
            error={Boolean(errors.email?.message)}
            helperText={errors.email?.message}
            {...register("email", { required: "Укажите почту" })}
            fullWidth
          />
          <input
            placeholder="Пароль"
            className="inputLogin mb-3"
            label="Пароль"
            type="password"
            error={Boolean(errors.password?.message)}
            helperText={errors.password?.message}
            {...register("password", { required: "Укажите пароль" })}
            fullWidth
          />

          <Box>
            <button style={{width: '100%'}}
              type="submit"
              fullWidth
              disabled={!isValid}
              className="btn2"
            >
              Войти
            </button>
          </Box>
          <Divider sx={{ mt: 3 }}></Divider>
        </form>
      </Box>
    </Box>
  );
};
